import { useState, useEffect, useMemo } from 'react';
import { IOrderReadyScreenConfig } from './OrderReadyScreenConfig';
import { channelId } from '../App';
import { useApiCall } from '../utils/useApiCall';
import { FailureMessage } from '../views/FailureMessage';

export const ReadyOrders = (props: {
  orderReadyScreenConfig: IOrderReadyScreenConfig;
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderBy = queryParams.get('orderBy'); // Get orderBy parameter from URL

  const {
    response: completedOrders,
    error,
    isLoading,
  } = useApiCall<number[]>(
    `/api/v1/public/order-ready-screen/${channelId}/ready-orders`,
    [],
    true,
  );

  // Ensure completedOrders is an array
  const ordersArray = Array.isArray(completedOrders) ? completedOrders : [];

  // State for current page
  const [currentPage, setCurrentPage] = useState(0);

  // Pagination logic
  const ordersPerPage = 16;
  const pageSeconds = 5;

  // Sort the orders if orderBy is set to 'orderNumberAsc'
  const sortedOrders = useMemo(() => {
    return ordersArray.slice().sort((a, b) => {
      if (orderBy === 'orderNumberAsc') {
        return a - b;
      }
      return 0;
    });
  }, [ordersArray, orderBy]);

  const pages = sortedOrders.length > 0
    ? Array.from(
        { length: Math.ceil(sortedOrders.length / ordersPerPage) },
        (_, i) =>
          sortedOrders.slice(i * ordersPerPage, (i + 1) * ordersPerPage),
      )
    : [];

  // Effect to change page every 5 seconds
  useEffect(() => {
    if (pages.length <= 1 || isLoading) {
      // No need to paginate if there's only one page, or we're still loading
      return;
    }

    const interval = setInterval(() => {
      setCurrentPage(prevPage => (prevPage + 1) % pages.length);
    }, pageSeconds * 1000);

    return () => clearInterval(interval);
  }, [pages.length, isLoading]);

  useEffect(() => {
    const defaultColor = 'black';
    const color = props.orderReadyScreenConfig.display.color.length
      ? props.orderReadyScreenConfig.display.color
      : defaultColor;
    
    document.body.style.backgroundColor = color;

    return () => {
      document.body.style.backgroundColor = ''; // Reset to default
    };
  }, [props.orderReadyScreenConfig.display.color]);

  if (isLoading) {
    return <FailureMessage message="Loading..." />;
  }

  const errorMsg = error ? 'Failed to get orders...' : undefined;

  return (
    <div
      id="app"
      className="App">
      <div id="hold">
        <div id="head">
          <span className="title">
            {props.orderReadyScreenConfig.locationName}
          </span>
        </div>
        <div id="content" className="container">
          {errorMsg ? (
            <FailureMessage color="white" message={errorMsg} />
          ) : (
            pages[currentPage]?.map((order, i) => (
              <div className="order" key={i}>
                {order}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
