import './App.css';
import { OrderReadyScreenConfig } from './components/OrderReadyScreenConfig';

export const channelId = window.location.pathname.substring(1);
export const App = () => {
  return (
    <div className="App">
      {channelId ? (
        <OrderReadyScreenConfig />
      ) : (
        <h1 style={{ color: 'black' }}>Location not found...</h1>
      )}
    </div>
  );
};
