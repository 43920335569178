import localConfig from './local';
import developmentConfig from './development';
import productionConfig from './production';
import stagingConfig from './staging';

type EnvironmentKey = 'local' | 'development' | 'staging' | 'production';

export interface LighthouseHost {
  apiHost: string;
}
export interface Config {
  lighthouse: LighthouseHost;
}

const ENV_CONFIG: Record<EnvironmentKey, Config> = {
  local: localConfig,
  development: developmentConfig,
  staging: stagingConfig,
  production: productionConfig,
};

const getConfig = () => {
  const environment = (process.env.REACT_APP_ENVIRONMENT ||
    'development') as keyof Record<EnvironmentKey, Config>;
  return ENV_CONFIG[environment];
};

export const config = getConfig();
