import { config } from '../config';

export class ResponseError extends Error {
  response: Response;
  constructor(message: string | undefined, res: Response) {
    super(message);
    this.response = res;
  }
}

export const get = async (url: RequestInfo | URL): Promise<Response> => {
  const res = await fetch(`${config.lighthouse.apiHost}${url}`);
  if (!res.ok) {
    throw new ResponseError('Bad response', res);
  }

  return res;
};
