import { useEffect, useState } from 'react';
import { ResponseError, get } from '../services/apiClient';

const errorMessages = {
  generalError: 'Records not found...',
  locationNotFound: 'Location not found...',
};

export const useApiCall = <T>(
  url: string,
  dependencies?: [],
  polling?: boolean,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({} as T);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await get(url);
        const res = await response.json();
        setResponse(res);
        setIsLoading(false);
        setErrorMsg('');
      } catch (err: ResponseError | unknown) {
        if (err instanceof ResponseError) {
          const errBody = await err.response.json();
          setErrorMsg(errBody.error?.message);
        } else {
          setErrorMsg(errorMessages.generalError);
        }
        setIsLoading(false);
      }
    };

    fetchRecords();

    if (polling) {
      const readyOrdersInterval = setInterval(fetchRecords, 15000);

      return () => {
        clearInterval(readyOrdersInterval);
      };
    }
  }, dependencies);

  return { isLoading, response, error: errorMsg };
};
